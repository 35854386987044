<template>
  <div class="medium-page">
    <!-- If we don't have the mentionTracking data just yet -->
    <template v-if="isLoading || !overview">
      <div
        class="mx-auto"
        style="max-width: 360px"
      >
        <lottie-animation
          loop
          key="antenna"
          file="loading-files-StOdYbIRa1.json"
        />
      </div>
    </template>

    <!-- Otherwise -->
    <template v-else>
      <!-- If we are still crawling the web -->
      <template v-if="overview.model.crawling_status !== 'completed'">
        <!-- The header buttons -->
        <div class="d-flex justify-space-between">
          <!-- Show the go back button -->
          <v-btn
            text
            color="primary"
            @click="$router.push({ name: 'MentionTrackingIndex' })"
          >
            <v-icon left>
              arrow_back
            </v-icon>

            Reports
          </v-btn>
        </div>

        <!-- Show an animation first -->
        <div class="mx-auto" style="max-width: 360px">
          <lottie-animation
            loop
            key="shaking-box"
            :file="overview.model.crawling_status === 'failed' ? 'error-zYjmTFCcVN.json' : 'animation_llcesz69.json'"
          />
        </div>

        <!-- Add an empty element to fix the gap -->
        <div style="margin-top: -60px;"></div>

        <!-- If there's some progress -->
        <div
          v-if="progressCounter > 0"
          class="mx-auto mb-16"
          style="max-width: 360px"
        >
          <!-- Show linear progress bar -->
          <v-progress-linear
            :buffer-value="progressPercentage + 5"
            :value="progressPercentage"
            color="primary"
            stream
          />
        </div>

        <!-- Show the text -->
        <div class="text-center text-h6 font-weight-bold">
          {{ overview.model.crawling_status === "failed" ? "An error occurred!" : "We're fetching your posts!" }}
          {{ progressCounter > 0 ? `(${nFormatter(progressCounter)}+)` : "" }}
        </div>

        <div class="text-center mt-2">
          {{ overview.model.crawling_status === "failed" ? "We could not prepare a report for this mention tracking, please contact us." : "It might take some minutes, you can leave the page and come back later." }}
        </div>
      </template>

      <!-- Otherwise, show the router-view child component -->
      <div
        v-else
        class="mention-tracking-child-view"
      >
        <router-view
          :key="reloadKey"
          :overview="overview"
          @reload="fetchDetails"
        />
      </div>
    </template>
  </div>
</template>

<script>
// Import helper functions
import messageEvents from "@/helpers/messageEvents"

// Import children components
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")

// Subscription ID for messageEvents
const crawlingSubscriptionId = Symbol("MentionTracking/View/Crawling")
const crawlCompletedSubscriptionId = Symbol("MentionTracking/View/CrawlCompleted")

// Export the SFC
export default {
  // Name of the component
  name: "MentionTrackingView",

  // Register children components
  components: {
    LottieAnimation,
  },

  // Define local data variables
  data: () => ({
    // If the data is being loaded
    isLoading: false,

    // The reload key to make sure the UI is always up-to-date
    reloadKey: null,

    // The progress percentage
    progressPercentage: 0,
    progressCounter: 0,
  }),

  // Define readonly data variables
  computed: {
    /**
     * Get the model data from Vuex store
     *
     * @returns {Object}
     */
    overview() {
      // Get the data from store
      return this.$store.getters["mentionTracking/detailByUuid"](this.$route.params.uuid)
    },
  },

  // Define local method functions
  methods: {
    /**
     * When called, fetch and update the campaign data in Vuex store
     *
     * @returns {void}
     */
    async fetchDetails(onlySponsored = false) {
      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isLoading = true

      // Also, reload the children view
      this.reloadKey = Symbol()

      // Wait for the load to be completed
      await this.$store.dispatch("mentionTracking/fetchDetails", {
        uuid: this.$route.params.uuid,
        onlySponsored,
      })

      // Hide the loaders
      this.$store.dispatch("loaders/remove", loaderId)
      this.isLoading = false
    },

    /**
     * Handle the crawling progress event
     *
     * @param {Object} event
     */
    handleCrawlProgress(event) {
      // Update the counter
      this.progressCounter = event.localData.counter

      // Total difference in report dates
      const totalDifference = Math.abs(dayjs(event.localData.endDate).diff(dayjs(event.localData.startDate), "day"))
      // Difference between end date and last post date
      const progressDifference = Math.abs(dayjs(event.localData.endDate).diff(dayjs(event.localData.lastPostDate), "day"))

      // Calculate the progress percentage
      const percentageValue = Math.floor((progressDifference / totalDifference) * 100)

      // If this value is more than current progress percentage
      if (percentageValue > this.progressPercentage) {
        // Update the progress percentage
        this.progressPercentage = percentageValue
      }
    }
  },

  /**
   * As soon as the component data has been created
   *
   * @returns {void}
   */
  created() {
    // Dispatch a request to create a network request
    this.fetchDetails()

    // Register a subscription to listen for crawl progress
    messageEvents.register({
      id: crawlingSubscriptionId,
      module: "mention-tracking",
      type: "progress",
      key: this.$route.params.uuid,
      callback: this.handleCrawlProgress
    })

    // Register a subscription to listen for crawl completion
    messageEvents.register({
      id: crawlCompletedSubscriptionId,
      module: "mention-tracking",
      type: "info",
      key: "crawl-completed",
      callback: (event) => {
        // Check if the UUID matches
        if (event.localData.modelUuid === this.$route.params.uuid) {
          // Fetch the details again
          this.fetchDetails()
        }
      }
    })
  },

  /**
   * Before the component is to be destroyed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // De-register the subscriptions
    messageEvents.deregister(crawlingSubscriptionId)
    messageEvents.deregister(crawlCompletedSubscriptionId)
  }
}
</script>